import { Typography, Box, Container, Grid } from "@mui/material";
import BackToTop from "../components/BackToTop";

const team = [
  {
    name: "Shahir Abdulla",
    designation: "Co-founder / CEO",
    imageLink: "./about/shahir.png",
    imageAlt: "Shahir Abdulla",
  },
  {
    name: "Muhammed Azhar",
    designation: "Co-founder / COO",
    imageLink: "./about/azhar.png",
    imageAlt: "Muhammed azhar",
  },
];

function About() {
  return (
    <div>
      <HeroSection
        title="About us"
        description="We are experts in providing top-notch services for many global brands to date to achieve desired results in business through best-in-class software and hardware solutions. We start by listening to the issues, requirements, and challenges, and continue with an effort to understand the business, market sector, and competitors to develop information that is combined with our technical knowledge, expertise, and research in order to provide the best solution at the lowest cost."
      />
      <TeamSection team={team} />
      <BackToTop />
    </div>
  );
}

function HeroSection({ title, description }) {
  return (
    <Box bgcolor="primary.main">
      <Container
        maxWidth="lg"
        sx={{
          paddingTop: "6rem",
          paddingBottom: "6rem",
          height: "100%",
          display: "flex",
        }}
      >
        <Grid container justifyContent="center" alignItems="center">
          <Box display="flex" flexDirection="column" alignItems="center">
            {/* <Grid item justifyContent='center' alignItems='center'> */}
            <Box alignItems="center">
              <Typography
                variant="h1"
                align="center"
                component="h1"
                color="secondary"
                gutterBottom
                sx={{
                  fontWeight: "700",
                }}
              >
                {title}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="h6"
                component="p"
                fontWeight="regular"
                textAlign={["center"]}
                sx={{
                  color: "secondary.main",
                  paddingTop: "1rem",
                  maxWidth: "800px",
                }}
              >
                {description}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Container>
    </Box>
  );
}

function TeamSection({ team }) {
  return (
    <Box
      bgcolor="secondary.main"
      sx={{
        marginTop: "4rem",
        marginBottom: "4rem",
        display: "flex",
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={6} justifyContent="center" alignItems="center">
          {team.map((member) => (
            <Grid key={member.imageLink} item sm={12} md={6}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <img
                  src={
                    member.name === "Muhammed Azhar"
                      ? require("../images/about/azhar.png")
                      : require("../images/about/shahir.png")
                  }
                  alt={member.imageAlt}
                />
                <Typography
                  variant="h5"
                  align="center"
                  component="h5"
                  color="primary"
                  gutterBottom
                  sx={{
                    paddingTop: "10px",
                    marginBottom: "0px",
                  }}
                >
                  {member.name}
                </Typography>
                <Typography
                  variant="h6"
                  align="center"
                  component="h6"
                  // color="primary"
                  gutterBottom
                >
                  {member.designation}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default About;
