import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import useMediaQuery from "@mui/material/useMediaQuery";
const navigationLinks = [
  { name: "About us", href: "/about" },
  { name: "Contact us", href: "/contact" },
  { name: "Codeit", href: "/codeit" },
  { name: "Home", href: "/" },
];

export default function Footer() {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <footer>
      <Box bgcolor="primary.main" pt={{ xs: 4, sm: 4 }} pb={{ xs: 3, sm: 3 }}>
        <Container maxWidth="lg">
          {/* max = 6 because in mediam sized display horixzontal scroll bar appears */}
          <Grid container spacing={isSmall ? 0 : 6}>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="h6"
                color="secondary"
                align={isSmall ? "center" : "left"}
                gutterBottom
                sx={{ fontWeight: "600" }}
              >
                © Autobonics 2022
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="h6"
                color="secondary"
                align={isSmall ? "center" : "right"}
                gutterBottom
              >
                made with 🤍
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </footer>
  );
}
