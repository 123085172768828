import Header from "./Header";
import Footer from "./Footer";
import Box from "@mui/material/Box";

export default function Layout({ children }) {
  return (
    <div>
      <Header />
      <Box id="back-to-top-anchor" />
      <Box
        // maxWidth="lg"
        sx={{
          paddingTop: "88px",
        }}
      >
        {children}
      </Box>
      <Footer />
    </div>
  );
}
