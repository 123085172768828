import React from "react";
import { Typography, Box, Container, Grid, TextField } from "@mui/material";
import CustomButton from "../components/Button";

export default function Contact() {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);
  };

  return (
    <Box backgroundColor="#009DEB10">
      <Container
        maxWidth="lg"
        sx={{
          paddingTop: { xs: "40px", sm: "40px", md: "80px" },
          paddingBottom: { xs: "40px", sm: "40px", md: "70px" },
        }}
      >
        <Grid container spacing={4}>
          <Grid
            item
            sm={12}
            md={6}
            sx={{
              paddingTop: { sx: "60px", sm: "60px", md: "80px" },
              paddingBottom: { sx: "20px", sm: "20px", md: "50px" },
              paddingRight: { md: "20px" },
            }}
          >
            <Typography
              variant="h2"
              component="h1"
              fontWeight="bold"
              textAlign={["left"]}
              color="primary"
              sx={{
                paddingTop: { xs: "20px", sm: "20px", md: "20px" },
              }}
            >
              Contact us
            </Typography>
            <Typography
              variant="h6"
              component="p"
              fontWeight="regular"
              textAlign={["left"]}
              sx={{ color: "text.secondary", paddingTop: "1rem" }}
            >
              Phone UAE: +971-565998878, India: +91-8848668847
            </Typography>
            <Typography
              variant="h6"
              component="p"
              fontWeight="regular"
              textAlign={["left"]}
              sx={{
                color: "text.secondary",
                paddingTop: "1rem",
                paddingBottom: "2rem",
              }}
            >
              Email: info@autobonics.com
            </Typography>
            <Typography
              variant="h4"
              component="h2"
              fontWeight="bold"
              textAlign={["left"]}
              color="primary"
            >
              Address
            </Typography>
            <Typography
              variant="h6"
              component="p"
              fontWeight="regular"
              textAlign={["left"]}
              sx={{ color: "text.secondary", paddingTop: "1rem" }}
            >
              📍 In5 Tech, Internet city, Dubai, UAE
            </Typography>
            {/* <Typography
              variant="h6"
              component="p"
              fontWeight="regular"
              textAlign={["left"]}
              sx={{
                color: "text.secondary",
              }}
            >
              Dubai, UAE
            </Typography> */}

            <Typography
              variant="h6"
              component="p"
              fontWeight="regular"
              textAlign={["left"]}
              sx={{
                color: "text.secondary",
                paddingTop: "20px",
              }}
            >
              📍 Malappuram, Kerala, India, 679582
            </Typography>
            {/* <Typography
              variant="h6"
              component="p"
              fontWeight="regular"
              textAlign={["left"]}
              sx={{
                color: "text.secondary",
                paddingBottom: "1rem",
              }}
            >
              {"  "}
              Kerala, India, 679582
            </Typography> */}
          </Grid>

          <Grid item sm={12} md={6}>
            <Box
              sx={{
                paddingTop: { sx: "40px", sm: "40px", md: "30px" },
                // paddingLeft: { md: "20px" },

                "& .MuiTextField-root": { marginBottom: 1 },
              }}
            >
              <form autoComplete="off" onSubmit={handleSubmit}>
                {/* <Typography
                  variant="h6"
                  component="p"
                  fontWeight="regular"
                  textAlign={["left"]}
                  sx={{ paddingBottom: "0.5rem" }}
                >
                  Fill the form to reach to us,
                </Typography> */}
                <TextField
                  required
                  fullWidth
                  id="name"
                  label="Name"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />

                <TextField
                  required
                  fullWidth
                  multiline
                  rows={4}
                  id="message"
                  label="Message"
                  type="text"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />

                <Box
                  sx={{
                    textAlign: "left",
                    paddingLeft: { md: "10px" },
                  }}
                >
                  <CustomButton onClick={handleSubmit}>
                    Send message
                  </CustomButton>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
