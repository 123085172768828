import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";

//pages
import About from "./pages/About";
import Contact from "./pages/Contact";
import ServicesPage from "./pages/Services";

let theme = createTheme({
  palette: {
    primary: {
      main: "#009DEB",
    },
    secondary: {
      main: "#FFF",
    },
    background: {
      default: "#F2F7F9",
      // paper: "#009DEB10",
    },
    text: {
      primary: "#000",
      secondary: "#676767",
    },
  },
  typography: {
    fontFamily: "Montserrat",
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 800,
  },
});

//Responsive font sizing
theme = responsiveFontSizes(theme);

function App() {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Layout>
            <Routes>
              <Route path="/" element={<ServicesPage />} />
              <Route path="about" element={<About />} />
              <Route path="contact" element={<Contact />} />
            </Routes>
          </Layout>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
