import Button from "@mui/material/Button";
export default function CustomButton({
  children,
  type,
  onClick,
  isWhite,
  ...props
}) {
  return (
    <Button
      onClick={onClick}
      variant={type ?? "contained"}
      color={isWhite ? "secondary" : "primary"}
      sx={{
        marginTop: 4,
        marginBottom: 4,
        borderRadius: 0,
        border: "none",
        fontWeight: "500",
        fontSize: { sm: "14px", md: "16px" },
        padding: { xs: "14px 16px", sm: "14px 16px", md: "16px 24px" },
        color: isWhite ? "text.secondary" : "white",
      }}
      {...props}
    >
      {children}
    </Button>
  );
}
