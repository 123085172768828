import { initializeApp } from "firebase/app";
import { getFirestore, Timestamp } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDMqxVw9bWZ06PFG5jybxm53TUXjYkrLMo",
  authDomain: "autobonics-a9656.firebaseapp.com",
  projectId: "autobonics-a9656",
  storageBucket: "autobonics-a9656.appspot.com",
  messagingSenderId: "330199867046",
  appId: "1:330199867046:web:243517f354a4529752de67",
};

// init firebase
initializeApp(firebaseConfig);

// init services
const db = getFirestore();
const auth = getAuth();

// timestamp
const timestamp = Timestamp;

export { db, auth, timestamp };
