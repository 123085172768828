import { Typography, Box, Container, Grid, Tabs, Tab } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import BackToTop from "../components/BackToTop";
import CustomButton from "../components/Button";
import { useRef, useState } from "react";
// import SocialMedia from "../components/SocialMedia";

export default function ServicesPage() {
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();

  return (
    <>
      <HeroSection
        title="Work with experts who can deliver world-class services."
        onTap={executeScroll}
      />

      <ServicesSection
        title="Services"
        subTitle="Transforming Your Ideas into Reality."
      />

      <div ref={myRef}>
        <PortfolioSection title="Portfolio" />
      </div>

      <PartnersSection />
      <BackToTop />
    </>
  );
}

function HeroSection({ title, onTap }) {
  return (
    <Box>
      <Container
        maxWidth="lg"
        sx={{
          paddingTop: { xs: "10px", sm: "10px", md: "70px" },
          paddingBottom: { xs: "20px", sm: "20px", md: "80px" },
        }}
      >
        <Grid container spacing={0}>
          <Grid item sm={12} md={7}>
            <Box
              component="img"
              sx={{
                // height: 233,
                // paddingRight: { md: "20px" },
                width: "100%",
                // maxHeight: { xs: 233, md: "80vh" },
                maxWidth: { xs: "100vw", sm: "100vw", md: "36vw" },
                // minHeight: "770px",
                objectFit: "cover",
                // paddingBottom: { xs: "50px", sm: "50px" },
              }}
              alt="Student learning graphics"
              src={require("../images/services/team.png")}
            />
          </Grid>

          <Grid
            item
            sm={12}
            md={5}
            sx={{
              paddingTop: { sx: "40px", sm: "40px", md: "60px" },
              paddingBottom: { sx: "20px", sm: "20px", md: "50px" },
            }}
          >
            <Typography
              variant="h3"
              component="h1"
              fontWeight="bold"
              sx={{
                marginTop: { xs: "30px", sm: "30px", md: "0px" },
                textAlign: { xs: "center", md: "left" },
              }}
            >
              {title}
            </Typography>
            <Box
              sx={{
                textAlign: { xs: "center", md: "left" },
              }}
            >
              <CustomButton onClick={onTap}>View our works</CustomButton>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

const services = [
  {
    title: "Web development",
    description:
      "Using best practices and tools, we can create a website/web app that will be a success in your business.",
    image: require("../images/services/service1.png"),
    altTag: "Web development",
  },
  {
    title: "Mobile apps",
    description:
      "We can create a cross platform applications for Android and iOS using flutter.",
    image: require("../images/services/service2.png"),
    altTag: "Mobile app development",
  },
  {
    title: "Hardware MVP",
    description:
      "IOT and other hardware MVP development services are available.",
    image: require("../images/services/service3.png"),
    altTag: "Hardware development",
  },
];

function ServicesSection({ title, subTitle }) {
  return (
    <Box backgroundColor="#009DEB10">
      <Container
        maxWidth="lg"
        sx={{
          paddingTop: { xs: "40px", sm: "40px", md: "70px" },
          paddingBottom: { xs: "40px", sm: "40px", md: "50px" },
        }}
      >
        <Grid container spacing={0}>
          <Grid
            item
            sm={12}
            md={6}
            sx={{
              paddingTop: { sx: "40px", sm: "40px", md: "60px" },
              paddingBottom: { sx: "20px", sm: "20px", md: "50px" },
              paddingRight: { md: "20px" },
            }}
          >
            <Typography
              variant="h4"
              component="h2"
              fontWeight="bold"
              textAlign={["left"]}
              color="primary"
              sx={{
                paddingBottom: { xs: "20px", sm: "20px", md: "20px" },
              }}
            >
              {title}
            </Typography>
            <Typography
              variant="h3"
              component="h3"
              fontWeight="bold"
              textAlign={["left"]}
              sx={{
                marginRight: { xs: "20px", sm: "20px", md: "100px" },
                marginBottom: { xs: "30px", sm: "30px", md: "0px" },
              }}
            >
              {subTitle}
            </Typography>
          </Grid>

          <Grid item sm={12} md={6}>
            {services.map((service, index) => (
              <ServiceCard key={index} {...service} />
            ))}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

function ServiceCard({ title, description, image, altTag }) {
  return (
    <Box
      sx={{
        // marginLeft: { xs: "0px", sm: "0px", md: "20px" },
        padding: { xs: "20px", sm: "20px", md: "20px" },
        borderRadius: "10px",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#fff",
        marginBottom: { xs: "20px", sm: "20px", md: "20px" },
      }}
    >
      <Box
        sx={{
          alignItems: "start",
          display: "flex",
          // alignContent: "center",
          // justifyContent: "space-between",
        }}
      >
        <Box
          component="img"
          sx={{
            marginRight: "20px",
          }}
          alt={altTag}
          src={image}
        />
        <Box>
          <Typography
            variant="h6"
            component="h2"
            fontWeight="bold"
            textAlign={["left"]}
          >
            {title}
          </Typography>
          <Typography variant="body2" component="p" textAlign={["left"]}>
            {description}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

const partners = [
  {
    name: "Inker Robotics",
    image: require("../images/services/inker.png"),
    website: "https://inkerrobotics.com/",
  },
  {
    name: "Amazon warehouse",
    image: require("../images/services/amazon.png"),
    website: "https://www.amazon.com/",
  },
  {
    name: "Acadevo",
    image: require("../images/services/acadevo.png"),
    website: "https://www.acadevolabs.com/",
  },
];

function PartnersSection() {
  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {partners.map((partner, index) => (
          <a
            key={index}
            href={partner.website}
            target="_blank"
            rel="noopener noreferrer"
            // style={{ marginRight: "32px" }}
          >
            <Box
              component="img"
              sx={{
                // marginRight: "20px",
                padding: { xs: "22px", sm: "22px", md: "40px" },
                // height: 233,
                // width: "100%",
                // maxHeight: { xs: 233, md: "80vh" },
                maxWidth: { xs: "32vw", sm: "32vw", md: "60vw" },
                // minHeight: "770px",
                // objectFit: "cover",
                // paddingTop: { xs: "50px", sm: "100px" },
              }}
              alt={partner.name}
              src={partner.image}
            />
          </a>
        ))}
      </Box>
    </Container>
  );
}

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function PortfolioSection({ title }) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container
      maxWidth="lg"
      sx={{
        paddingTop: { xs: "40px", sm: "40px", md: "50px" },
        paddingBottom: { xs: "40px", sm: "40px", md: "50px" },
        // flexDirection: "column",
      }}
    >
      <Box sx={{ width: "100%", display: "flex" }}>
        <Typography
          variant="h4"
          component="h2"
          fontWeight="bold"
          textAlign={["left"]}
          sx={{
            marginRight: { xs: "20px", sm: "20px", md: "100px" },
            // marginBottom: { xs: "30px", sm: "30px", md: "0px" },
            marginTop: { xs: "12px", sm: "12px", md: "8px" },
          }}
        >
          {title}
        </Typography>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          textColor="primary"
          indicatorColor="primary"
          aria-label="basic tabs"
        >
          <Tab label="Mobile app" {...a11yProps(0)} />
          <Tab label="Websites" {...a11yProps(1)} />
          <Tab label="Hardware" {...a11yProps(2)} />
        </Tabs>
      </Box>

      <Box
        sx={{
          height: { xs: "20px", sm: "20px", md: "20px" },
        }}
      />

      <TabPanel value={value} index={0}>
        <GridRightSection
          title="Codeit."
          description="Codeit! a multi-device coding platform to learn future technology skills such as robotics, AI,  programming and electronics."
          image={require("../images/services/codeit.png")}
          altTag="Codeit!"
          buttonText="Visit website"
          buttonLink="https://code.autobonics.com/"
          playStoreLink="https://play.google.com/store/apps/details?id=com.autobonics.codeit"
        />
        <GridLeftSection
          title="Abzolute"
          description="Abzolute is a market place for discovering homepreneurs, freelancers, creative people, artists and other business service providers in 24+ categories."
          image={require("../images/services/abzolute.png")}
          altTag="Abzolute"
          playStoreLink="https://play.google.com/store/apps/details?id=com.autobonics.abzolute"
        />
        <GridRightSection
          title="Integral"
          description="Integral is an all-in-one social platform designed for creatives of all types, whether you are a maker, artist, gamer, crafter or just looking for some inspiration."
          image={require("../images/services/integral.png")}
          altTag="Integral"
          playStoreLink="https://play.google.com/store/apps/details?id=com.autobonics.integral"
        />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <GridRightSection
          title="Autobonics.com"
          description="Our company’s own website. Which showcase our product Pluto with databse integration for product's pr-eorder data collection."
          image={require("../images/services/autobonics.png")}
          altTag="Codeit!"
          // buttonText="Visit website"
          // buttonLink="https://autobonics.com/"
        />
        <GridLeftSection
          title="HoMe Spot"
          description="'HoMe Spot' a Hostel/Mess management software to simplify hostel and mess management, expenses tracking and hostel inmates more efficiently."
          image={require("../images/services/homespot.png")}
          altTag="Abzolute"
          buttonText="Visit website"
          buttonLink="https://hostelsmess.web.app/"
        />
        <GridRightSection
          title="Robovation"
          description="Robovation club website with user registration, event management features."
          image={require("../images/services/robovation.png")}
          altTag="Integral"
          buttonText="Visit website"
          buttonLink="https://robovation.web.app/"
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <GridRightSection
          title="Pluto"
          description="Our company’s own product. "
          image={require("../images/services/plutoAuto.png")}
          altTag="Pluto"
          buttonText="Visit website"
          buttonLink="https://autobonics.com/"
        />
        <GridLeftSection
          title="RoVi"
          description="An artifical intelligent humanoid robot for education purpose."
          image={require("../images/services/rovi.png")}
          altTag="RoVi"
          // buttonText="Visit website"
          // buttonLink="https://hostelsmess.web.app/"
        />
        <GridRightSection
          title="XLR8"
          description="Lidar based safety sensor for Amazon warehouse security. Speed and acceleration measuring device with new algorithm to ensure maximum safety for yard Marshall."
          image={require("../images/services/xlr8.png")}
          altTag="XLR8"
          // buttonText="Visit website"
          // buttonLink="https://robovation.web.app/"
        />
        <GridLeftSection
          title="ABFS"
          description="Advanced Biomatric Scanner Attendance system which can be used in different industries like Hospital, School, College, etc with backend and web app to track and manage the users."
          image={require("../images/services/abfs.png")}
          altTag="ABFS"
          buttonText="Visit web app"
          buttonLink="https://abfs.web.app/"
        />
      </TabPanel>
    </Container>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function GridRightSection({
  title,
  description,
  image,
  altTag,
  playStoreLink,
  buttonText,
  buttonLink,
}) {
  return (
    <Grid container spacing={0}>
      <Grid item sm={12} md={6}>
        <Box
          component="img"
          sx={{
            width: "100%",
            objectFit: "cover",
          }}
          alt={altTag}
          src={image}
        />
      </Grid>
      <GridText
        title={title}
        description={description}
        playStoreLink={playStoreLink}
        buttonLink={buttonLink}
        buttonText={buttonText}
      />
    </Grid>
  );
}

function GridLeftSection({
  title,
  description,
  image,
  altTag,
  playStoreLink,
  buttonText,
  buttonLink,
}) {
  const isLarge = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  return (
    <Grid container spacing={0}>
      {isLarge && (
        <GridText
          title={title}
          description={description}
          playStoreLink={playStoreLink}
          buttonLink={buttonLink}
          buttonText={buttonText}
        />
      )}
      <Grid item sm={12} md={6}>
        <Box
          component="img"
          sx={{
            width: "100%",
            objectFit: "cover",
          }}
          alt={altTag}
          src={image}
        />
      </Grid>
      {!isLarge && (
        <GridText
          title={title}
          description={description}
          playStoreLink={playStoreLink}
          buttonLink={buttonLink}
          buttonText={buttonText}
        />
      )}
    </Grid>
  );
}

function GridText({
  title,
  description,
  playStoreLink,
  buttonText,
  buttonLink,
}) {
  return (
    <Grid
      item
      sm={12}
      md={6}
      sx={{
        display: "flex",
      }}
    >
      <Box
        sx={{
          marginLeft: { xs: "0px", sm: "0px", md: "60px" },
          marginRight: { xs: "0px", sm: "0px", md: "60px" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          // // alignItems: "center",
          alignContent: "center",
          align: "center",
        }}
      >
        <Typography
          variant="h4"
          component="h2"
          fontWeight="bold"
          textAlign={["left"]}
          sx={{
            marginRight: { xs: "20px", sm: "20px", md: "100px" },
            // marginBottom: { xs: "30px", sm: "30px", md: "0px" },
            marginTop: { xs: "12px", sm: "12px", md: "8px" },
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="h6"
          component="p"
          textAlign={["left"]}
          fontWeight="regular"
          sx={{
            color: "text.secondary",
            paddingTop: { xs: "0rm", sm: "0", md: "1rem" },
            paddingBottom:
              !buttonText && !playStoreLink
                ? { xs: "30px", sm: "30" }
                : { xs: "0px", sm: "0" },
          }}
        >
          {description}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {playStoreLink && (
            <Box
              component="img"
              typeof="button"
              onClick={() => window.open(playStoreLink)}
              sx={{
                marginRight: "20px",
                width: "100%",
                maxWidth: { xs: "200px", sm: "200px", md: "210px" },
                objectFit: "contain",
                paddingTop: { xs: "30px", sm: "30" },
                paddingBottom: !buttonText
                  ? { xs: "30px", sm: "30" }
                  : { xs: "0px", sm: "0" },
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              alt="Play Store"
              src={require("../images/services/google-play-badge.png")}
            />
          )}
          {buttonText && (
            <Box>
              <CustomButton
                onClick={() => {
                  window.open(buttonLink, "_blank");
                }}
              >
                {buttonText}
              </CustomButton>
            </Box>
          )}
        </Box>
      </Box>
      <Grid />
    </Grid>
  );
}
