import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

const navigationLinks = [
  { name: "Home", href: "/" },
  { name: "About us", href: "/about" },
  { name: "Contact us", href: "/contact" },
];

export default function Header() {
  const [open, setOpen] = useState(false);

  const location = useLocation();
  const path = location.pathname;

  return (
    <AppBar elevation={0} color="secondary">
      <Container maxWidth="lg">
        <Toolbar
          disableGutters
          sx={{
            height: 80,
            display: "flex",
          }}
        >
          <Logo />
          <Hidden mdDown>
            {navigationLinks.map((link) => (
              <NavLink
                key={link.href}
                link={link.href}
                name={link.name}
                path={path}
              />
            ))}
          </Hidden>
          <Hidden mdUp>
            <IconButton onClick={() => setOpen(true)}>
              <MenuIcon color="primary" />
            </IconButton>
          </Hidden>
        </Toolbar>
      </Container>

      <SwipeableDrawer
        anchor="right"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
            backgroundColor: "background.default",
          },
        }}
      >
        <div>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          {navigationLinks.map((link) => (
            //todo add background colour on particular pages
            <ListItem key={link.href}>
              <NavLink name={link.name} link={link.href} path={path} />
            </ListItem>
          ))}
        </List>
      </SwipeableDrawer>
    </AppBar>
  );
}

function Logo() {
  const navigate = useNavigate();
  return (
    <Box
      component="img"
      onClick={() => navigate("/")}
      sx={{
        marginRight: "auto",
        cursor: "pointer",
        // width: "100%",
        maxWidth: { xs: "120px", sm: "120px", md: "150px" },
        objectFit: "cover",
      }}
      alt="Autobonics logo"
      src={require("../images/logo.png")}
    />
  );
}

export function NavLink({ name, link, path, isFooter, isSmall }) {
  const navigate = useNavigate();
  return (
    <Typography
      onClick={() => navigate(link)}
      color={isFooter ? "secondary" : "primary"}
      align={isFooter ? (isSmall ? "left" : "right") : "center"}
      variant="h6"
      sx={{
        marginLeft: 4,
        cursor: "pointer",
        fontSize: "16px",
        fontWeight: "regular",
        textAlign: "bottom",
        borderBottom: path === link ? "3px solid #009DEB" : "none",
        ":hover": {
          borderBottom: !isFooter
            ? (theme) => `3px solid ${theme.palette.primary.main}`
            : "none",
        },
      }}
    >
      {name}
    </Typography>
  );
}
